.special {
  .single-title, .single-subtitle {
    text-align: right;
    padding-bottom: 1.5rem;

    //color: #00b0ff;
    background: -webkit-linear-gradient(91deg, #CD00BE, #465BE5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
